import { Component } from '@angular/core';

@Component({
  selector: 'app-manage-internal-user',
  templateUrl: './manage-internal-user.component.html',
  styleUrl: './manage-internal-user.component.scss'
})
export class ManageInternalUserComponent {

}
