import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-designate-agent-modal',
  templateUrl: './designate-agent-modal.component.html',
  styleUrl: './designate-agent-modal.component.scss'
})
export class DesignateAgentModalComponent {
  visible: boolean = false;
  constructor(private ref: DynamicDialogRef){

  }

  showDialog() {
    this.visible = true;
  }
  closeModal(isNewAgent?:boolean) {
    this.ref.close(isNewAgent);
  }
}
