<div class="grid mb-2 px-6 mt-4">
    <!-- <button type="button" class="secondary default">Back To Sign In</button> -->
    <a class="text-end" style="float:left" href="javascript:history.back()">Go Back</a>
</div>
<div class="grid">
    <div class="lg:col-11 md:col-11 sm:col-12 pl-6">
        <div class="border-left-3 border-yellow-600 bg-panel-gray p-4">
            <div class="text-sm">USER GUIDE</div>
            <h1 class="mt-0 capitalize">MANAGE USERS</h1>
        </div>
    </div>
</div>
<div class="grid">
    <div class="lg:col-10 md:col-10 sm:col-12 pl-6">
        <div class="container px-4">
            <ol>
                <li class="mb-3">‘Manage FAA Users’ link is available only to internal users with admin role.
                    <img class="w-6 block" src="../../../assets/images/manage-user-img1.png">
                </li>
                <li class="mb-3">Once logged in as an internal user with admin role, the user will will be able to use the filters for further refining the results. <strong>Note:</strong> By default the Internal users list is sorted by alphabetical order (First Name/ Last Name)</li>
                <li class="mb-3">Internal admin also can add additional internal users by clicking on ‘Add New User’ button.</li>
                <li class="mb-3">You will be redirected to ‘Add New User’ screen, where you can enter the email address (FAA email address) and click on Verify.
                    <img class="w-6 block" src="../../../assets/images/manage-user-img2.png">
                </li>
                <li class="mb-3">Once email is verified, the search results will display and under the Actions column, click on ‘Select to assign role’.
                    <img class="w-6 block" src="../../../assets/images/manage-user-img3.png">
                </li>
                <li class="mb-3">You can select the Role and the Status for the new user from the options in the drop-down list as in the screenshot below and click on Save.
                    <img class="w-6 block" src="../../../assets/images/manage-user-img4.png">
                </li>
                <li class="mb-3">A success message that the user has been added successfully will be displayed and the user will be redirected back to the ‘Manage Users’ page.
                    <img class="w-6 block" src="../../../assets/images/manage-user-img5.png">
                </li>
                <li class="mb-3">Once the user has been added to the ‘Internal Users List’, you can either search for the new user by using the search filters or pagination and update the role or the status by clicking on ‘Edit’ link.
                    <img class="w-6 block" src="../../../assets/images/manage-user-img6.png">
                </li>
                <li class="mb-3">The Internal users can also see the ‘Resource Links’ tab with the following sub menu items:
                    <img class="w-6 block" src="../../../assets/images/manage-user-img7.png">
                </li>
            </ol>
        </div>
    </div>
</div>