import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { AuthService } from '../../_auth/auth.service';
import { UserDetailsService } from '../../shared/services/user-details.service';
import { saveAs } from "file-saver";
import { formatDate } from '@angular/common';
import { Table } from 'primeng/table';
import { SortEvent } from 'primeng/api';

interface Dataset { 
  airmanname: String, 
  airmanemail: String, 
  agenttype: String,
  agentname: String,
  agentaddress: String,
  agentphone: Number,
  agentfax: Number,
  agentemail: String,
  agentpoc: String,
  pocphone: Number,
  pocfax: Number,
  pocemail: String,
  lastupdate: String
}

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrl: './search-results.component.scss'
})
export class SearchResultsComponent implements OnChanges{
 @Input() searchResults:any
 @Input() isAirmenAgentSearch:boolean

 @ViewChild('table') table: Table;
  users: Dataset[];
  public userData: any;
  first: number = 0;
  sortOrder:string;
  isFtnAgentSearch: boolean;
  constructor(private authService: AuthService, private userDetailService: UserDetailsService){

  }

  ngOnInit() {
    this.userData = this.authService.getUserData();
    this.users = this.searchResults.items;
    this.isFtnAgentSearch = this.isAirmenAgentSearch.valueOf();
  }
  ngOnChanges(changes:any) {
    if(this.table){
      this.table.reset();
      this.first = 0;
    }
    this.users= changes.searchResults.currentValue.items
    this.isFtnAgentSearch= changes.isAirmenAgentSearch.currentValue.valueOf();
    this.sortOrder = '';
  }

  onSort(event: SortEvent){
    if(event.order === 1){
      this.sortOrder = 'Ascending';
    }else if(event.order === -1){
      this.sortOrder = 'Descending';
    }
  }

  downloadSearchReport() {
      if(this.isFtnAgentSearch)
      {
        this.userDetailService.getSearchUsersReport(this.sortOrder).subscribe((results: any)=>{
          const blob = new Blob([results], { type: 'text/csv' });
          saveAs(blob, 'USAS_Export_'+ formatDate(new Date(),'MMddyyyy_hhmma','en-US') +'.csv');
        });
      }
      else{
        this.userDetailService.getArOwnerSearchUsersReport(this.sortOrder).subscribe((results: any)=>{
          const blob = new Blob([results], { type: 'text/csv' });
          saveAs(blob, 'USAS_Export_'+ formatDate(new Date(),'MMddyyyy_hhmma','en-US') +'.csv');
        });
      }


  }
}
