<div class="grid mb-2 px-6 mt-4">
    <!-- <button type="button" class="secondary default">Back To Sign In</button> -->
    <a class="text-end" style="float:left" href="javascript:history.back()">Go Back</a>
</div>
<div class="grid">
    <div class="lg:col-11 md:col-11 sm:col-12 pl-6">
        <div class="border-left-3 border-yellow-600 bg-panel-gray p-4">
            <div class="text-sm">USER GUIDE</div>
            <h1 class="mt-0 capitalize">INTERNAL USER- SEARCH</h1>
        </div>
    </div>
</div>
<div class="grid">
    <div class="lg:col-10 md:col-10 sm:col-12 pl-6">
        <div class="container px-4">
            <ol>
                <li class="mb-3">Search can be performed by Internal users to look for an Agent information associated to an Airmen/Operator. (Airmen/Operators do not have access to Search)
                </li>
                <li class="mb-3">As an Internal user once logged in you will see the search filters as in the scerenshot below and also by default the U.S. Agent List is displayed as well. The default display for the list is based on ‘Last Updated on’  field.
                    <img class="w-6 block" src="../../../assets/images/search-img1.png">
                </li>
                <li class="mb-3">Please use the search filters listed below to refine the seach in the list and get the desired results or use the pagination at the botton of the list to navigate between pages:
                    <ul>
                        <li>First Name</li>
                        <li>Last Name</li>
                        <li>Email</li>
                        <li>FTN</li>
                        <li>Updated from Date</li>
                        <li>Updated to Date</li>
                    </ul>
                    <p><strong>Note</strong> User the ‘Clear’ button to clear the values from the seacrh filters and also clear the U.S. Agent list and revert of default display.</p>
                </li>
                <li>Once the search is performed, the internal user aslo has an option to download the serch results. This will donwload the search results in the CSV format and can be used to refine the results further.
                    <img class="w-6 block" src="../../../assets/images/search-img2.png">
                </li>
            </ol>
        </div>
    </div>
</div>