<div class="grid mb-2 px-6 mt-4">
    <!-- <button type="button" class="secondary default">Back To Sign In</button> -->
    <a class="text-end" style="float:left" href="javascript:history.back()">Go Back</a>
</div>
<div class="grid">
    <div class="lg:col-11 md:col-11 sm:col-12 pl-6">
        <div class="border-left-3 border-yellow-600 bg-panel-gray p-4">
            <div class="text-sm">USER GUIDE</div>
            <h1 class="mt-0 capitalize">EXTERNAL USER/PASSWORD RESET</h1>
        </div>
    </div>
</div>
<div class="grid">
    <div class="lg:col-10 md:col-10 sm:col-12 pl-6">
        <div class="container px-4">
            <ol>
                <li>If you would like
                    to reset your password, please navigate to the USAS login page ‘ <a
                        href="https://usas.faa.gov/signin"
                        target="_blank">https://usas.faa.gov/signin</a>’ and click on ‘Forgot
                    Password’ link
                    <img class="w-6 block" src="../../../assets/images/image1.png">
                </li>
                <li>Please enter the
                    email that you would like to reset your password for on the ‘Request to Reset Password’ screen and
                    click on
                    ‘Next’.
                    <img class="w-6 block" src="../../../assets/images/image11.png">
                </li>
                <li>You will be
                    redirected to the screen where your will need to answer only ‘<strong>ONE</strong>’ of the security
                    questions
                    that were provided during account creation process. Enter the <strong>correct</strong> answer and
                    click on
                    ‘Submit’.
                    <img class="w-6 block" src="../../../assets/images/image12.png">
                </li>
                <li>You will see a
                    ‘Password Reset Confirmation’ message displayed.
                    <img class="w-6 block" src="../../../assets/images/image13.png">
                </li>
                <li>Please login to
                    the email account and click on the ‘Reset Password link’
                    <img class="w-6 block" src="../../../assets/images/image14.png">
                </li>
                <li>Please provide a
                    new password as per the password requirements, confirm password and click on ‘Submit’.
                    <img class="w-6 block" src="../../../assets/images/image15.png">
                </li>
                <li>You will see the
                    ‘Password Changed Successfully’ message displayed. Click on ‘Go to Sign in’ button.
                    <img class="w-6 block" src="../../../assets/images/image16.png">
                </li>
                <li>You will be
                    redirected to the disclaimer page. Click on ‘Accept’ button and you will be redirected to the ‘USAS
                    login’ page.
                </li>
                <li>Please use the
                    email address and the updated password to sign into USAS.
                    <img class="w-6 block" src="../../../assets/images/image17.png">
                </li>
            </ol>
        </div>
    </div>
</div>