import { Component } from '@angular/core';

@Component({
  selector: 'app-search-internal-user',
  templateUrl: './search-internal-user.component.html',
  styleUrl: './search-internal-user.component.scss'
})
export class SearchInternalUserComponent {

}
