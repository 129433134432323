import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from './../../app-config.module';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggerService } from './logger/logger.service';
import { AuthService } from '../../_auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {

  _appSettings;
  userSearchResults: any
  assignRole : boolean = false;
  selectedUserData:any
  listOfRoles: any;
  searchCriteria: any;

  constructor( private httpClient: HttpClient, private logger: LoggerService, 
    private authService: AuthService) {
    this._appSettings = AppSettings;
   }

   public validateUserDetails(userData:any): Observable<any> {
    return this.httpClient
      .post<any>(
        `${this._appSettings.API_ENDPOINT}User/ValidateUsasUser`, userData
        )
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  public logOut(): Observable<any> {
    return this.httpClient
      .get<any>(
        `${this._appSettings.API_ENDPOINT}auth/logout`
        )
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  public getSearchAgent(userData:any): Observable<any> {
    this.searchCriteria = userData;

    return this.httpClient
      .post<any>(
        `${this._appSettings.API_ENDPOINT}Agent/SearchAgent`, userData
        )
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  public getArOwnerSearchAgent(userData:any): Observable<any> {
    this.searchCriteria = userData;

    return this.httpClient
      .post<any>(
        `${this._appSettings.API_ENDPOINT}Agent/SearchArAgent`, userData
        )
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  public getInternalUsers(data:any): Observable<any> {
    return this.httpClient
      .post<any>(`${this._appSettings.API_ENDPOINT}User/GetInternalUsers`, data)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  public addUser(data:any): Observable<any> {
    return this.httpClient
      .post<any>(`${this._appSettings.API_ENDPOINT}User/AddUser`, data)
      .pipe(catchError((err) => this.errorHandler(err)));
  }
  public editInternalUsers(data:any): Observable<any> {
    return this.httpClient
      .post<any>(`${this._appSettings.API_ENDPOINT}User/EditUser`, data)
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  public getSearchUsersLDAP(userData:any): Observable<any> {
    return this.httpClient
      .post<any>(
        `${this._appSettings.API_ENDPOINT}User/SearchUsersinLDAP`, userData
        )
      .pipe(catchError((err) => this.errorHandler(err)));
  }

  public getAllRoles(): Observable<any> {
    return this.httpClient
      .get<any>(
        `${this._appSettings.API_ENDPOINT}Lookup/GetAllRoles`
        )
      .pipe(catchError((err) => this.errorHandler(err)));
  }

   setUserSearchResults(data:any){
    this.userSearchResults = data;
   }

   getUserSearchResults(){
    return this.userSearchResults;
   }

  errorHandler(error: any): any {
    this.logger.error(error);
    return throwError(new Error(error));
  }

  public getSearchUsersReport(sortOrder:string): Observable<unknown> {
    if(sortOrder && sortOrder !== ''){
    this.searchCriteria.orderBy =  [
      {
        sortDirection: sortOrder,       
        propertyName: "operatorFirstName"     
      }
    ]
  }
    return this.httpClient
      .post(
        `${this._appSettings.API_ENDPOINT}agent/ExportSearchresult`, this.searchCriteria, {responseType: 'text' as any })
      .pipe(catchError((err) => this.errorHandler(err))); 
  }

  public getArOwnerSearchUsersReport(sortOrder:string): Observable<unknown> {
    if(sortOrder && sortOrder !== ''){
    this.searchCriteria.orderBy =  [
      {
        sortDirection: sortOrder,       
        propertyName: "arOwnerName"     
      }
    ]
  }
    return this.httpClient
      .post(
        `${this._appSettings.API_ENDPOINT}agent/ExportArOwnerSearchresult`, this.searchCriteria, {responseType: 'text' as any })
      .pipe(catchError((err) => this.errorHandler(err))); 
  }

  public USAgentAddressVerification(data:any): Observable<any> {
    return this.httpClient
      .post<any>(`${this._appSettings.API_ENDPOINT}Lookup/USAgentAddressVerification`, data)
      .pipe(catchError((err) => this.errorHandler(err)));
  }
  public GetExternalUserDetails(data:any): Observable<any> {
    return this.httpClient
      .post<any>(`${this._appSettings.API_ENDPOINT}ExternalUser/GetExternalUserDetails`, data)
      .pipe(catchError((err) => this.errorHandler(err)));
  }
  public GetUserSecurityQuestions(data:any): Observable<any> {
    return this.httpClient
      .post<any>(`${this._appSettings.API_ENDPOINT}ExternalUser/GetUserSecurityQuestions`, data)
      .pipe(catchError((err) => this.errorHandler(err)));
  }
  public UpdateExternalUserDetails(data:any): Observable<any> {
    return this.httpClient
      .post<any>(`${this._appSettings.API_ENDPOINT}ExternalUser/UpdateExternalUserDetails`, data)
      .pipe(catchError((err) => this.errorHandler(err)));
  }
  public UpdateUserPassword(data:any): Observable<any> {
    return this.httpClient
      .post<any>(`${this._appSettings.API_ENDPOINT}ExternalUser/UpdateUserPassword`, data)
      .pipe(catchError((err) => this.errorHandler(err)));
  }
  public UpdateSecurityQuestions(data:any): Observable<any> {
    return this.httpClient
      .post<any>(`${this._appSettings.API_ENDPOINT}ExternalUser/UpdateSecurityQuestions`, data)
      .pipe(catchError((err) => this.errorHandler(err)));
  }
  
}
