<main id="main-content" class="grid nested-grid ">
    <div class="col-12 mt-4 px-6">
        <h1>Search Airman / Operator / Aircraft Registered Owner</h1>
        <div class="p-5 bg-cyan-50 border-300" style="padding-top: 20px !important;">
            <app-searchpanel (searchResults)="handleSearch($event)" searchType="Airman/Operator" (isAirmenAgentSearch) = "IsSearchTypeAirmen($event)"></app-searchpanel>
        </div>
        <div *ngIf="searchResults">
            <app-search-results [searchResults]="searchResults" [isAirmenAgentSearch] = "isAirmenAgentSearch" ></app-search-results>
        </div>
    </div>
</main>
