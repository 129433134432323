<main id="main-content" class="grid nested-grid">
    <div class="col-8 mt-4 px-6">
        <div class="grid mb-2" *ngIf="userData?.userIsActive !== 'Y'">
            <button type="button" class="secondary default" (click)="signin()">Back To Sign In</button>
        </div>
        <div class="grid mb-2">
            <div class="lg:col-10 md:col-10 sm:col-12">
                <h1>Contact Us</h1>
            </div>
        </div>
        <div class="grid">
            <div class="col-12">
                <h2>If you have any questions or need help</h2>
                <div class="p-3 border-left-3 border-yellow-600" style="background-color: #8080801a">
                    <ul>
                        <li>
                            <b>Your username and password – </b>
                            <span><a class="font-medium no-underline link-button cursor-pointer ml-0"
                                    (click)="forgotPassword()" aria-label="Link to reset password"
                                    title="Reset Password">Click here</a></span>here to reset your password
                        </li>
                        <li class="mt-2">
                            <b>Your FTN account –</b>
                            <ul>
                                <li>MedXPress - <span><a class="font-medium no-underline link-button cursor-pointer ml-0"
                                    href="http://medxpress.faa.gov/" aria-label="Redirect to MedXPress"
                                    title="Redirect to MedXPress" target="_blank">Click here</a></span>to look up your FTN number in your MedXPress Profile.
                                </li>
                                <li>IACRA - <span><a class="font-medium no-underline link-button cursor-pointer ml-0"
                                    href="https://iacra.faa.gov/" aria-label="Redirect to IACRA"
                                    title="Redirect to IACRA" target="_blank">Click here</a></span>to look up your FTN number in your IACRA Profile.
                                </li>
                            </ul>
                        </li>
                        <li class="mt-2">
                            <b>Your N Number and Serial number –</b>
                            <ul>
                                <li>
                                    AR Registered Owners - <span><a class="font-medium no-underline link-button cursor-pointer ml-0"
                                        href="https://registry.faa.gov/aircraftinquiry"
                                        aria-label="Link to aircraft inquiry" title="Aircraft Inquiry" target="_blank">Click here</a></span>to look up your N Number and Serial Number through Aircraft Inquiry.
                                </li>
                            </ul>
                        </li>
                        <li class="mt-2">
                            If you need further assistance, please contact by email: <span><a class="font-medium no-underline link-button cursor-pointer ml-0" href="mailto:APP-AIT-USAS@faa.gov" aria-label="Feedback on using the USAS application" title="Feedback">APP-AIT-USAS&#64;faa.gov</a></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</main>