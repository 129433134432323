<div class="grid mb-2 px-6 mt-4">
    <!-- <button type="button" class="secondary default">Back To Sign In</button> -->
    <a class="text-end" style="float:left" href="javascript:history.back()">Go Back</a>
</div>
<div class="grid">
    <div class="lg:col-11 md:col-11 sm:col-12 pl-6">
        <div class="border-left-3 border-yellow-600 bg-panel-gray p-4">
            <div class="text-sm">USER GUIDE</div>
            <h1 class="mt-0 capitalize">EXTERNAL USERS - LOGIN/ACCOUNT REQUEST</h1>
        </div>
    </div>
</div>
<div class="grid">
    <div class="lg:col-10 md:col-10 sm:col-12 pl-6">
        <div class="container px-4">
            <ol>
                <li class="mb-3">Launch the following URL - <a href="https://usas.faa.gov/signin"
                        target="_blank">https://usas.faa.gov/signin</a></li>
                <li class="mb-3">You will be redirected to the USAS Login page as in the screenshot below:
                    <img class="w-6 block" src="../../../assets/images/image1.png">
                </li>
                <li class="mb-3">Create an account by clicking on ‘Request New User Account’ link.</li>
                <li class="mb-3">You will be redirected to the ‘Request a Account’ page and will need to fill out the values for the
                    required fields for the following sections:</li>
                <ul>
                    <li class="mb-3">Personal Information</li>
                    <li class="mb-3">Security Questions and Answers. <strong style="color: red;">Note:</strong> all three questions
                        should be unique.Create Password. <strong style="color: red;">Note:</strong> Refer to the
                        Password
                        Requirements</li>
                    <li class="mb-3"><span style="color: red;">Please save the security questions and answers and the email and
                            password
                            to be used later for login and password reset purpose</span>.
                        <img class="w-6 block" src="../../../assets/images/image2.png">
                    </li>
                </ul>
                <li class="mb-3">Once the required information is provided, please click submit at the bottom of the ‘Request An
                    Account’
                    page.</li>
                <li class="mb-3">Resolve any validation errors and click ‘Submit’.</li>
                <li class="mb-3">The following message will be displayed.
                    <img class="w-6 block" src="../../../assets/images/image3.png">
                </li>
                <li class="mb-3">Once the Account is created, save your email and password for to be used for re-login to USAS.</li>
                <li class="mb-3">Login to the email account (Inbox) which was used for account creation.</li>
                <li class="mb-3">The following email will be in your inbox:
                    <img class="w-6 block" src="../../../assets/images/image4.png">
                </li>
                <li class="mb-3">Please click on the ‘Email Verification link’ to confirm your email address.</li>
                <li class="mb-3">The message for account activation will be displayed. Click on ‘Return to Login’ button.
                    <img class="w-6 block" src="../../../assets/images/image5.png">
                </li>
                <li class="mb-3">You will be redirected to the disclaimer page. Click on ‘Accept’ button.
                    <img class="w-6 block" src="../../../assets/images/image6.png">
                </li>
                <li class="mb-3">You will be redirected to the USAS login page:
                    <img class="w-6 block" src="../../../assets/images/image7.png">
                </li>
                <li class="mb-3">Enter the Email address and password that was user for account creation and activation and click on
                    ‘Sign in’.
                    <img class="w-6 block" src="../../../assets/images/image8.png">
                </li>
                <li class="mb-3">You will be successfully logged in and will be on the ‘Manage U.S. Agent’ page.
                    <img class="w-6 block" src="../../../assets/images/image9.png">
                </li>
            </ol>
        </div>
    </div>
</div>