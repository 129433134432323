import { Component } from '@angular/core';

@Component({
  selector: 'app-external-user-designate',
  templateUrl: './external-user-designate.component.html',
  styleUrl: './external-user-designate.component.scss'
})
export class ExternalUserDesignateComponent {

}
