import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { StateService } from '../../shared/services/agent-state.service';
import { Observable, map } from 'rxjs';
import { State } from '../../shared/models/states-model';
import { UserDetailsService } from '../../shared/services/user-details.service';

@Component({
  selector: 'app-add-agent-modal',
  templateUrl: './add-agent-modal.component.html',
  styleUrl: './add-agent-modal.component.scss'
})

export class AddAgentModalComponent implements OnInit {

  agentForm: UntypedFormGroup | any;
  agentType: string;
  agentData: any;
  states$: Observable<(State[] | undefined)>;
  selectedAgentType: boolean;
  checked: boolean = false;
  stateOptions: any[];
  addressEntered: any[];
  addressOptions: any[] ;
  showAddressVerification:boolean = false;
  showAddressError:boolean = false;
  loadingState:boolean = false;
  addressLine1:string;
  addressline2: string;
  city : string;
  state :string;
  zipCode: string;
  zip4Code : string;
  constructor(private fb: UntypedFormBuilder, private config: DynamicDialogConfig, private cdr: ChangeDetectorRef,
    private ref: DynamicDialogRef, private stateService: StateService, private userDetailService: UserDetailsService) {

    this.agentData = config?.data;

      this.agentForm = this.fb.group({
        isAgentIndividual: this.fb.control(false),
        agentCompanyName: this.fb.control(''),
        selectedAddress:this.fb.control(''),
        selectedCategory: this.fb.control(''),
        middleName: this.fb.control('', Validators.pattern(/^[a-zA-Z'-][a-zA-Z\s'-]*$/)),
        firstName: this.fb.control('', [Validators.required, Validators.pattern(/^[a-zA-Z'-][a-zA-Z\s'-]*$/)]),
        lastName: this.fb.control('', [Validators.required, Validators.pattern(/^[a-zA-Z'-][a-zA-Z\s'-]*$/)]),
        suffix: this.fb.control('', Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){1,}$/)),
        pocmiddleName: this.fb.control('', Validators.pattern(/^[a-zA-Z'-][a-zA-Z\s'-]*$/)),
        pocfirstName: this.fb.control(''),
        poclastName: this.fb.control(''),
        pocsuffix: this.fb.control('', Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){1,}$/)),
        pocEmail: this.fb.control('', Validators.pattern(/^([a-zA-Z0-9][\w-]*(?:\.\w*)?)@([\w-]+(?:\.[a-zA-Z]{2,10})+)$/i)),
        pocPhone: this.fb.control('', Validators.pattern(/^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$/)),
        pocFax: this.fb.control('',  Validators.pattern(/(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/)),
        addressLine1: this.fb.control('', [Validators.required, Validators.pattern(/^(?!.*\b(P(?:O|ost\s*O(?:ffice)?)?\s*Box|APO|FPO|DPO|PO|PPO|P.O|APOBOX|FPOBOX|DPOBOX|PPOBOX)\b).*\s*\S.{1,}$/i)]),
        addressline2: this.fb.control('', Validators.pattern(/^(?!.*\b(P(?:O|ost\s*O(?:ffice)?)?\s*Box|APO|FPO|DPO|PO|PPO|P.O|APOBOX|FPOBOX|DPOBOX|PPOBOX)\b).*\s*\S.{1,}$/i)),
        city: this.fb.control('', [Validators.required, Validators.pattern(/^\s*(?=\S)(?:[a-zA-Z\s'\/-]){2,}$/)]),
        state: this.fb.control('', [Validators.required, Validators.pattern(/^[a-zA-Z-\s'\/s]*$/)]),
        zipCode: this.fb.control('', [Validators.required, Validators.pattern(/[0-9]{5}/)]),
        zip4Code: this.fb.control('', Validators.pattern(/[0-9]{4}/)),
        email: this.fb.control('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9][\w-]*(?:\.\w*)?)@([\w-]+(?:\.[a-zA-Z]{2,10})+)$/i)]),
        phone: this.fb.control('', Validators.pattern(/^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$/)),
        fax: this.fb.control('', Validators.pattern(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/)),
        isAgentMilitaryEmp: this.fb.control(''),
        certify: this.fb.control('', Validators.required),
      }); 
  }

  ngOnInit(): void {
    this.states$ = this.stateService.getStateDetails().pipe(
      map(states => [{stateCd: 'Select', description: null}, ...(states ?? [])]));
    
    if (this.agentData) {
      this.selectedAgentType = this.agentData.isAgentIndividual === 'Y' ? false : true;
      
      this.agentForm.patchValue(this.agentData);
      this.agentForm.patchValue({'isAgentIndividual':this.selectedAgentType});
      this.checked = this.agentData.isAgentMilitaryEmp === 'Y' ? true : false
      if(this.agentData.isAgentIndividual === 'N') {
        this.agentForm.patchValue({'pocfirstName':this.agentData.firstName});
        this.agentForm.patchValue({'pocmiddleName':this.agentData.middleName});
        this.agentForm.patchValue({'poclastName':this.agentData.lastName});
        this.agentForm.patchValue({'pocsuffix':this.agentData.suffix}); 
        this.agentForm.patchValue({'firstName':''});
        this.agentForm.patchValue({'middleName':''});
        this.agentForm.patchValue({'lastName':''});
        this.agentForm.patchValue({'suffix':''});       
      }
      
    }
    this.setCategoryValidators();
  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.agentForm.patchValue({'state':this.agentData.stateCd});
      this.cdr.detectChanges();
    },0)
  }

  handleaddAgent() {
    if(this.agentForm.get('state').value === 'Select'){
      this.agentForm.get('state').setErrors({'required': true});
    }
    if (this.agentForm.valid ) {
      this.closeAgentModal(false);  
    }else if (!this.agentForm.valid){
      this.agentForm.markAllAsTouched();
    }
  }

  closeAgentModal(isCancel:boolean=true) {
    this.ref.close({formData:this.agentForm,isCancel:isCancel});
  }

  setCategoryValidators() {
    const agentCompanyName = this.agentForm.get('agentCompanyName');
    const pocfirstName = this.agentForm.get('pocfirstName');
    const poclastName = this.agentForm.get('poclastName');
    const firstName = this.agentForm.get('firstName');
    const lastName = this.agentForm.get('lastName');

    this.agentForm.get('isAgentIndividual').valueChanges.subscribe((typeOfAgent: any) =>{
      if(typeOfAgent === false) {
        agentCompanyName.setValidators(Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/));
        pocfirstName.setValidators(Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/));
        poclastName.setValidators(Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/));
        firstName.setValidators([Validators.required, Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/)]);
        lastName.setValidators( [Validators.required, Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/)]);
      }
      if(typeOfAgent === true) {
        agentCompanyName.setValidators([Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/), Validators.required]);
        pocfirstName.setValidators(Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/));
        poclastName.setValidators(Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/));
        firstName.setValidators(Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/));
        lastName.setValidators( Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/));
      }

      agentCompanyName.updateValueAndValidity();
      pocfirstName.updateValueAndValidity();
      poclastName.updateValueAndValidity();
      firstName.updateValueAndValidity();
      lastName.updateValueAndValidity();
    });  

    if(this.agentForm.get('isAgentIndividual').value === false) {
      agentCompanyName.setValidators(Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/));
      pocfirstName.setValidators(Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/));
      poclastName.setValidators(Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/));
      firstName.setValidators([Validators.required, Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/)]);
      lastName.setValidators( [Validators.required, Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/)]);
    }
    if(this.agentForm.get('isAgentIndividual').value === true) {
      agentCompanyName.setValidators([Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/), Validators.required]);
      pocfirstName.setValidators(Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/));
      poclastName.setValidators(Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/));
      firstName.setValidators(Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/));
      lastName.setValidators( Validators.pattern(/^\s*(?:[a-zA-Z][a-zA-Z-\s\\\\'\/]*){2,}$/));
    }

    agentCompanyName.updateValueAndValidity();
    pocfirstName.updateValueAndValidity();
    poclastName.updateValueAndValidity();
    firstName.updateValueAndValidity();
    lastName.updateValueAndValidity();
  }

  handleAddressVerification(){
    this.showAddressVerification = false;
    this.addressLine1 = this.agentForm.get('addressLine1').value;
    this.addressline2 = this.agentForm.get('addressline2').value;
    this.city = this.agentForm.get('city').value;
    this.state = this.agentForm.get('state').value;
    this.zipCode = this.agentForm.get('zipCode').value;
    this.zip4Code = this.agentForm.get('zip4Code').value;
    if(this.addressLine1 && this.city && this.state && this.zipCode){
      this.loadingState = true;
      const name = this.addressline2 ? this.addressLine1+','+this.addressline2+','+this.city+','+ this.state+','+ this.zipCode : this.addressLine1+','+this.city+','+ this.state+','+ this.zipCode
      this.addressEntered = [{ addressLine1: this.addressLine1, addressline2:this.addressline2,
        city:this.city, state: this.state, zipCode: this.zipCode,zip4Code:this.zip4Code, key: 'E' }]
      const reqData = {
        "street": this.addressLine1,
        "street2": this.addressline2,
        "city": this.city,
        "state": this.state,
        "zipCode": this.zipCode
      }
          this.agentForm.get('selectedAddress').setValue(this.addressEntered[0]);
          this.agentForm.get('selectedCategory').setValue(null);
          
      this.userDetailService.USAgentAddressVerification(reqData).subscribe((data:[]) => {
        if (data) {
          this.showAddressError = data && data.length >0 ? false : true
          this.addressOptions = data; 
          this.showAddressVerification = true;
          this.loadingState = false;
        }
     });

    }
  }


  addressVerification(){
    if(this.addressLine1.trim() !== this.agentForm.get('addressLine1').value.trim() || 
    this.addressline2.trim() !== this.agentForm.get('addressline2').value.trim() ||
    this.city.trim() !== this.agentForm.get('city').value.trim() || this.state !== this.agentForm.get('state').value ||
    this.zipCode !== this.agentForm.get('zipCode').value || this.zip4Code !== this.agentForm.get('zip4Code').value){
      this.showAddressVerification = false;
    }
  }


  firstRadioClick(address: any){
    this.agentForm.get('selectedCategory').setValue('');
    this.agentForm.get('selectedAddress').setValue(address);
  }

  secondRadioGroup(category: any){    
    this.agentForm.get('selectedAddress').setValue('');
    this.agentForm.get('selectedCategory').setValue(category);
    this.cdr.detectChanges();
  }

  confirmAddress(){
    const selectedCategory = this.agentForm.get('selectedCategory').value ? this.agentForm.get('selectedCategory').value : this.agentForm.get('selectedAddress').value ;
    this.showAddressVerification = false;
    let addressLine1, addressline2, city, state, zipCode, zip4Code;
    if(this.agentForm.get('selectedCategory').value){
       addressLine1 = selectedCategory.primaryNumber+' '+selectedCategory.streetName +' '+selectedCategory.streetSuffix+' '+(selectedCategory.streetPostdirection ? selectedCategory.streetPostdirection : "");
       city = selectedCategory.cityName;
       state = selectedCategory.state;
       zipCode = selectedCategory.zipCode;
       zip4Code = selectedCategory.plus4Code;
       addressline2 = '';
    }
    else if (this.agentForm.get('selectedAddress').value ){
      addressLine1 = selectedCategory.addressLine1;
      addressline2 = selectedCategory.addressline2;
      city = selectedCategory.city;
      state = selectedCategory.state;
      zipCode = selectedCategory.zipCode;
      zip4Code = selectedCategory.zip4Code;

    }
    
    this.agentForm.patchValue({'addressLine1':addressLine1});
    this.agentForm.patchValue({'addressline2':addressline2});
    this.agentForm.patchValue({'city':city});
    this.agentForm.patchValue({'state':state}); 
    this.agentForm.patchValue({'zipCode':zipCode});
    this.agentForm.patchValue({'zip4Code':zip4Code});
  }
}




