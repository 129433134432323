import { Component } from '@angular/core';
import { AuthService } from "../../_auth/auth.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-internal-landing-page',
  templateUrl: './internal-landing-page.component.html',
  styleUrl: './internal-landing-page.component.scss'
})
export class InternalLandingPageComponent {
  public searchResults:any
  public isAirmenAgentSearch: boolean
  constructor(private authService: AuthService, public router: Router,){
    if(!this.authService.isInternalUser() && !this.authService.isInternalAdminUser()){
      this.router.navigate(["/landing"]);
    }
  }

  handleSearch(event:any){
    this.searchResults = event;
  }

  IsSearchTypeAirmen(event:boolean)
  {
    this.isAirmenAgentSearch = event;
  }

}
