import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, FormBuilder, FormGroup } from '@angular/forms';
import { UserDetailsService } from '../../shared/services/user-details.service';

@Component({
  selector: 'app-searchpanel',
  templateUrl: './searchpanel.component.html',
  styleUrl: './searchpanel.component.scss'
})
export class SearchpanelComponent implements OnInit {
  searchForm: FormGroup;
  dateTime: Date = new Date();
  @Output() searchResults: EventEmitter<any> = new EventEmitter();
  @Output() isAirmenAgentSearch: EventEmitter<boolean> = new EventEmitter();
  @Input() searchType: string;
  userRoles: any[];
  agentCategory = false;

  constructor(private fb: FormBuilder, private userDetailService: UserDetailsService) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      ftn:[''],
      email: [''],
      startDate: [''],
      endDate: [''],
      selectedRole: [''],
      nNumber:[''],
      serialNumber:[''],
      selectedCategory:[false]
    });
    this.handleSearch(); // default the search screen with results
    if(this.searchType == "InternalUser"){
      this.userDetailService.getAllRoles().subscribe((data)=>{
        this.userRoles = data;
      });
    }
    this.searchForm.get('selectedCategory')?.valueChanges.subscribe((typeOfAgent: any) =>{
      if(typeOfAgent === false){
        this.agentCategory = false;
        this.handleSearch(); 
      }
      else if (typeOfAgent === true){
        this.agentCategory = true;
        this.handleSearch(); 
      }

    })
  }

  handleSearch(){
    if(this.searchType == "Airman/Operator"){
      if(this.agentCategory)
      {
        const data = {
          skip: 0,
          take: 100,
          operatorFirstName: this.searchForm.controls['firstName'].value,
          operatorLastName: this.searchForm.controls['lastName'].value,
          nNumber: this.searchForm.controls['nNumber'].value,
          serialNo: this.searchForm.controls['serialNumber'].value,
          updatedFrom: this.searchForm.controls['startDate'].value,
          updatedTo: this.searchForm.controls['endDate'].value,
          orderBy: [
            {
              sortDirection: "Descending",       
              propertyName: "lastUpdateDate"     
            }
          ]
        }
        this.userDetailService.getArOwnerSearchAgent(data).subscribe((results)=>{
          this.isAirmenAgentSearch.emit(false);
          this.searchResults.emit(results);
        })
      }
      else{
        const data = {
          skip: 0,
          take: 100,
          operatorFirstName: this.searchForm.controls['firstName'].value,
          operatorLastName: this.searchForm.controls['lastName'].value,
          FTN: this.searchForm.controls['ftn'].value,
          operatorEmail: this.searchForm.controls['email'].value,
          updatedFrom: this.searchForm.controls['startDate'].value,
          updatedTo: this.searchForm.controls['endDate'].value,
          orderBy: [
            {
              sortDirection: "Descending",       
              propertyName: "lastUpdateDate"     
            }
          ]
        }
        this.userDetailService.getSearchAgent(data).subscribe((results)=>{
          this.isAirmenAgentSearch.emit(true);
          this.searchResults.emit(results);          
        })
      }      
    }
    else if(this.searchType == "InternalUser"){
      const data = {
        skip: 0,
        take: 100,
        firstName: this.searchForm.controls['firstName'].value,
        lastName: this.searchForm.controls['lastName'].value,
        email: this.searchForm.controls['email'].value,
        roleId: this.searchForm.controls['selectedRole'].value,
      }
      this.userDetailService.getInternalUsers(data).subscribe((results)=>{
        this.userDetailService.setUserSearchResults(results.items);
        this.searchResults.emit(results);
    })

    }
  }
  
  clearSearch(){
    this.searchForm.reset();
    this.searchForm.get('selectedCategory')?.setValue(false);
    this.handleSearch();
  }
}
