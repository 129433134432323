    <div class="add-agent-modal">
        <div [formGroup]="agentForm">
            <div class="content-wrapper text-sm">
                <div class="block-spacing">
                    <h3>
                        Select type of U.S. Agent
                    </h3>
                    <div class="p-field-radiobutton" style="display: flex; margin-bottom: 10px;">
                        <div>
                            <p-radioButton name="isAgentIndividual" [value]="false" label="Individual" inputId="addAgent1"
                            formControlName="isAgentIndividual"></p-radioButton>
                            <span><i class="fas fa-info-circle fa-sm ml-1" 
                                pTooltip="Please select this if your U.S. Agent is an Individual"></i></span>
                        </div>
                    </div>
                    <div class="p-field-radiobutton" style="display: flex;">
                        <div>
                            <p-radioButton name="isAgentIndividual" [value]="true" label="Service Agent Company"
                            inputId="addAgent2"   formControlName="isAgentIndividual"></p-radioButton>
                            <span><i class="fas fa-info-circle fa-sm ml-1" 
                                pTooltip="Please select this if your U.S. Agent is a Service Agent Company"></i></span>
                        </div>
                    </div>
                </div>
                <h3 *ngIf="agentForm?.get('isAgentIndividual')?.value === false">Individual Agent Details</h3>
                <h3 *ngIf="agentForm?.get('isAgentIndividual')?.value === true">Service Agent Company Details</h3>
                <div class="form-items-wrapper" *ngIf="agentForm?.get('isAgentIndividual')?.value==true">
                    <div class="form-item-wrapper" style="width: 100%;">
                        <label for="entityName">Company Name<span class="asterik">*</span>
                            <span><i class="fas fa-info-circle fa-sm ml-1" 
                                pTooltip="Enter the Service Agent Company Name"></i></span>
                        </label>
                        <input pInputText id="entityName" type="text" maxLength="50" formControlName="agentCompanyName" style='width: 100%;' pKeyFilter="alpha" required/>
                        <div *ngIf="agentForm?.get('agentCompanyName')?.invalid  && agentForm?.get('agentCompanyName')?.errors['required'] && agentForm?.get('agentCompanyName')?.touched"
                            class="alert danger-alert error-font">Company Name is required. </div>
                            <div class="alert danger-alert error-font" *ngIf="agentForm?.get('agentCompanyName')?.errors && agentForm?.get('agentCompanyName')?.errors['pattern']">
                                Please enter valid Company name.
                            </div>
                    </div>
                </div>
                <div *ngIf="agentForm?.get('isAgentIndividual')?.value === false" class="form-items-wrapper block-spacing">
                    <p-checkbox formControlName="isAgentMilitaryEmp" value="U.S. Agent is a military employee, and I am registering on behalf of the DOD" inputId="military" class="mr-1" [(ngModel)]="checked" [binary]="true"></p-checkbox>
                    <label for="military">U.S. Agent is a military employee registering on behalf of DOD</label>
                </div>
                <div class="form-items-wrapper" *ngIf="agentForm?.get('isAgentIndividual')?.value==false">
                    <div class="form-item-wrapper">
                        <label for="firstName">First Name<span class="asterik">*</span>
                            <span><i class="fas fa-info-circle fa-sm ml-1" 
                                pTooltip="Enter the U.S. Agent’s legal First Name"></i></span>
                        </label>
                        <input pInputText id="firstName" type="text" maxLength="50" formControlName="firstName" required/>
                        <div *ngIf="agentForm?.get('firstName')?.invalid  && agentForm?.get('firstName')?.errors['required'] && agentForm?.get('firstName')?.touched"
                            class="alert danger-alert error-font">First Name is required. </div>
                            <div class="alert danger-alert error-font" *ngIf=" agentForm?.get('firstName')?.errors && agentForm?.get('firstName')?.errors['pattern']">
                                Please enter valid first name.
                            </div>
                    </div>
                    <div class="form-item-wrapper">
                        <label for="middleName"style="margin-bottom: 5px;">Middle Name 
                            <span><i class="fas fa-info-circle fa-sm ml-1" 
                                pTooltip="Enter the U.S. Agent’s legal Middle Name/Initial"></i></span>
                        </label>
                        <input pInputText id="middleName" type="text" maxLength="15" formControlName="middleName"/>
                        <div class="alert danger-alert error-font" *ngIf=" agentForm?.get('middleName')?.errors && agentForm?.get('middleName')?.errors['pattern']">
                            Please enter valid middle name.
                        </div>
                    </div>
                    <div class="form-item-wrapper">
                        <label for="lastName">Last Name<span class="asterik">*</span>
                            <span><i class="fas fa-info-circle fa-sm ml-1" 
                                pTooltip="Enter the U.S. Agent’s legal Last Name"></i></span>
                        </label>
                        <input pInputText id="lastName" type="text" maxLength="50" formControlName="lastName" required/>
                        <div *ngIf="agentForm?.get('lastName')?.invalid  && agentForm?.get('lastName')?.errors['required'] && agentForm?.get('lastName')?.touched"
                            class="alert danger-alert error-font">Last Name is required. </div>
                            <div class="alert danger-alert error-font" *ngIf="agentForm?.get('lastName')?.errors && agentForm?.get('lastName')?.errors['pattern']">
                                Please enter valid last name.
                            </div>
                    </div>
                    <div class="form-item-wrapper">
                        <label for="suffix" style="margin-bottom:4px;">Suffix
                            <span><i class="fas fa-info-circle fa-sm ml-1" 
                                pTooltip="Enter a Suffix if your U.S. Agent's legal name contains a suffix at the end of their name e.g. SR, JR, II, III"></i></span>
                        </label>
                        <input pInputText id="suffix" type="text" class="suffix-width" maxLength="4" formControlName="suffix" />
                        <div class="alert danger-alert error-font" *ngIf=" agentForm?.get('suffix')?.errors && agentForm?.get('suffix')?.errors['pattern']">
                            Please enter valid suffix.
                        </div>
                    </div>
                </div>

                <div class="grid">
                    <div class="col">
                        <div class="form-items-wrapper">
                            <div class="form-item-wrapper" >
                                <label for="addressLine1">Address Line 1<span class="asterik">*</span>
                                    <span><i class="fas fa-info-circle fa-sm ml-1" 
                                        pTooltip="Enter the U.S. Agent’s Address (must be a physical address not a PO Box)"
                                        *ngIf="agentForm?.get('isAgentIndividual')?.value === false"></i>
                                        <i class="fas fa-info-circle fa-sm ml-1" 
                                        pTooltip="Enter the Service Agent Company Address (must be a physical address not a PO Box)"
                                        *ngIf="agentForm?.get('isAgentIndividual')?.value === true"></i></span>
                                </label>
                                <input pInputText id="addressLine1" type="text" class="max-input-width" maxLength="66" (keyup)="addressVerification()" formControlName="addressLine1" required/>
                                <div *ngIf="agentForm?.get('addressLine1')?.invalid  && agentForm?.get('addressLine1')?.errors['required'] && agentForm?.get('addressLine1')?.touched"
                                    class="alert danger-alert error-font">Address Line1 is required. </div>
                                <div class="alert danger-alert error-font" *ngIf="agentForm?.get('addressLine1')?.errors && agentForm?.get('addressLine1')?.errors['pattern']">
                                    Please enter valid Address Line 1. PO/APO/FPO/DPO box numbers are not allowed.
                                </div>
                            </div>
                        </div>
                        <div class="form-items-wrapper">
                            <div class="form-item-wrapper">
                                <label for="addressLine2">Address Line 2</label>
                                <input pInputText id="addressLine2" type="text" class="max-input-width" maxLength="66" (keyup)="addressVerification()" formControlName="addressline2" />
                                <div class="alert danger-alert error-font" *ngIf=" agentForm?.get('addressline2')?.errors && agentForm?.get('addressline2')?.errors['pattern']">
                                    PO/APO/FPO/DPO box numbers are not allowed.
                                </div>
                            </div>
                        </div>
                        <div class="form-items-wrapper">
                            <div class="form-item-wrapper">
                                <label for="city">City<span class="asterik">*</span></label>
                                <input pInputText id="city" type="text" class="avg-input-width" maxLength="30" (keyup)="addressVerification()" formControlName="city" required/>
                                <div *ngIf="agentForm?.get('city')?.invalid  && agentForm?.get('city')?.errors['required'] && agentForm?.get('city')?.touched"
                                class="alert danger-alert error-font">City is required. </div>
                                    <div class="alert danger-alert error-font" *ngIf="agentForm?.get('city')?.errors && agentForm?.get('city')?.errors['pattern']">
                                        Please enter valid city.
                                    </div>
                            </div>
                            <div class="form-item-wrapper" *ngIf="states$ | async as states" style="margin-top: -1px ">
                                <label for="state">State<span class="asterik">*</span></label>
                                <p-dropdown
                                [options]="states"
                                optionLabel="stateCd"
                                id="state"
                                optionValue="stateCd"
                                [style]="{ 'width': '6rem', 'height': '27px', 'margin-top': '0px', 'border-radius':'0px', 'border': '1px solid slategray', 'text-align':'center'}"
                                [styleClass]="agentForm?.get('state')?.touched && !agentForm?.get('state')?.valid ? 'invalid-border' : 'valid-border'"
                                placeholder="Select"
                                formControlName="state"
                                (focusout)="addressVerification()"
                                (keyup)="addressVerification()"
                                (keydown)="addressVerification()"
                                (onChange)="addressVerification()"
                                ></p-dropdown>
                                <div *ngIf="agentForm?.get('state')?.invalid  && agentForm?.get('state')?.errors['required'] && agentForm?.get('state')?.touched"
                                class="alert danger-alert error-font">State is required. </div>
                                    <div class="alert danger-alert error-font" *ngIf="agentForm?.get('state')?.errors && agentForm?.get('state')?.errors['pattern']">
                                        Please enter valid state.
                                    </div>
                            </div>
                        </div>
                        <div class="form-items-wrapper">
                            <div class="form-item-wrapper">
                                <label for="zipcode">Zipcode<span class="asterik">*</span>
                                    <span>
                                        <i class="fas fa-info-circle fa-sm ml-1" 
                                        pTooltip="Enter the U.S. Agent’s ZIP code, give the additional 4-digit Zip code, if known"
                                        *ngIf="agentForm?.get('isAgentIndividual')?.value === false"></i>
                                        <i class="fas fa-info-circle fa-sm ml-1" 
                                        pTooltip="Enter the Service Agent Company ZIP code, give the additional 4-digit Zip code, if known" 
                                        *ngIf="agentForm?.get('isAgentIndividual')?.value === true"></i></span>
                                </label>
                                <input pInputText id="zipcode" maxLength="5" class="min-input-width" type="text" (keyup)="addressVerification()" formControlName="zipCode" required/>
                                <div *ngIf="agentForm?.get('zipCode')?.invalid  && agentForm?.get('zipCode')?.errors['required'] && agentForm?.get('zipCode')?.touched"
                                class="alert danger-alert error-font">Zipcode is required. </div>
                                    <div class="alert danger-alert error-font" *ngIf=" agentForm?.get('zipCode')?.errors && agentForm.get('zipCode')?.errors['pattern']">
                                        Please enter a valid zipcode.
                                    </div>
                            </div>
                            <div class="form-item-wrapper" style="margin-bottom: -14px!important;">
                                <span style="line-height: 85px;">-</span>
                            </div>
                            <div class="form-item-wrapper" style="margin-top: 28px;">
                                <input pInputText id="zip4Code" maxLength="4" class="min-input-width" type="text" 
                                    formControlName="zip4Code" (keyup)="addressVerification()" aria-label="zip4code"/>
                                <div class="alert danger-alert error-font" *ngIf=" agentForm?.get('zip4Code')?.errors && agentForm.get('zip4Code')?.errors['pattern']">
                                    Please enter a valid zipcode.
                                </div>
                            </div>
                            <div class="form-item-wrapper mb-0 mt-3">
                                <button type="submit"  class="primary default" (click)="handleAddressVerification()">Verify Address</button>
                            </div>
                        </div>

                        <div class="form-items-wrapper">
                            <div class="form-item-wrapper">
                                <label for="phone">Phone
                                    <span>
                                        <i class="fas fa-info-circle fa-sm ml-1" 
                                        pTooltip="Enter the U.S. Agent’s phone number" *ngIf="agentForm?.get('isAgentIndividual')?.value === false"></i>
                                        <i class="fas fa-info-circle fa-sm ml-1" 
                                        pTooltip="Enter the Service Agent Company phone number" *ngIf="agentForm?.get('isAgentIndividual')?.value === true"></i></span>
                                </label>
                                <p-inputMask id="phone" class="avg-input-width"  mask="+1 (999) 999-9999"  [autoClear]="false" formControlName="phone" type="tel" placeholder="+1 (999) 999-9999"></p-inputMask>
                                <div class="alert danger-alert error-font" *ngIf="agentForm?.get('phone')?.errors && agentForm?.get('phone')?.errors.pattern">
                                    Please enter valid Phone number. 
                                </div>
                            </div>
                            <div class="form-item-wrapper">
                                <label for="fax" aria-label="fax">Fax</label>
                                <p-inputMask id="fax" class="avg-input-width"  mask="+1 (999) 999-9999" [autoClear]="false" formControlName="fax" aria-labelledby="fax" type="tel" placeholder="+1 (999) 999-9999"></p-inputMask>
                                <div class="alert danger-alert error-font" *ngIf="agentForm?.get('fax')?.errors && agentForm?.get('fax')?.errors.pattern">
                                    Please enter a valid Fax number. 
                                </div>
                            </div>
                        </div>

                        <div class="form-items-wrapper">
                            <div class="form-item-wrapper">
                                <label for="email">Email<span class="asterik">*</span>
                                    <span>
                                        <i class="fas fa-info-circle fa-sm ml-1" 
                                        pTooltip="Enter the U.S. Agent’s email address (name@domain.com)" *ngIf="agentForm?.get('isAgentIndividual')?.value === false"></i> 
                                        <i class="fas fa-info-circle fa-sm ml-1" 
                                        pTooltip="Enter the Service Agent Company email address (name@domain.com)" *ngIf="agentForm?.get('isAgentIndividual')?.value === true"></i></span>
                                </label>
                                <input pInputText id="email" class="avg-input-width" type="email" maxLength="45" formControlName="email" required/>
                                <div *ngIf="agentForm?.get('email')?.invalid  && agentForm?.get('email')?.errors['required'] && agentForm?.get('email')?.touched"
                                class="alert danger-alert error-font">Email is required. </div>
                                <div class="alert danger-alert error-font" *ngIf=" agentForm?.get('email') && agentForm?.get('email')?.errors && !agentForm?.get('email')?.errors['required']">
                                    Please enter a valid email address.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col bg-cyan-50 p-3" style="height: max-content;" *ngIf="loadingState">
                        <h3 class="font-bold text-base">Address verification</h3>
                        <p class="text-sm line-height-2">You can choose to keep the address you entered or choose from recommended matching address</p>
                        <div class="flex mb-3">
                            <p-skeleton shape="circle" size="4rem" styleClass="mr-2" />
                            <div>
                                <p-skeleton width="10rem" styleClass="mb-2" />
                                <p-skeleton width="5rem" styleClass="mb-2" />
                                <p-skeleton width="5rem" />
                            </div>
                        </div>
                    </div>

                    <div class="col bg-cyan-50 p-3" style="height: max-content;" *ngIf="showAddressVerification">
                        <h3 class="font-bold text-base">Address verification</h3>
                        <p class="text-sm line-height-2">You can choose to keep the address you entered or choose from recommended matching address</p>
                        <div>
                            <h3 class="font-bold text-base">Address you entered</h3>
                            <div *ngFor="let address of addressEntered" class="field-checkbox">
                                <p-radioButton 
                                    name="selectedAddress"
                                    [inputId]="address.key" 
                                    [value]="address"
                                    formControlName="selectedAddress"
                                    (onClick)="firstRadioClick(address)"/>
                                <label [for]="address.key" class="ml-2 text-sm" *ngIf="address.addressline2">
                                    {{ address.addressLine1 }}, {{ address.addressline2 }}, {{ address.city }}, {{ address.state }} {{ address.zipCode }}<span *ngIf="address.zip4Code">-</span>{{ address.zip4Code }}
                                </label>
                                <label [for]="address.key" class="ml-2 text-sm" *ngIf="!address.addressline2">
                                    {{ address.addressLine1 }}, {{ address.city }}, {{ address.state }} {{ address.zipCode }}<span *ngIf="address.zip4Code">-</span>{{ address.zip4Code }}
                                </label>
                            </div>
                        </div>
                        
                        <div>
                            <h3 class="font-bold text-base">Alternative address recommended</h3>
                            <div *ngFor="let category of addressOptions" class="field-checkbox">
                                <p-radioButton 
                                    name="selectedCategory"
                                    [inputId]="category.key" 
                                    [value]="category"
                                    formControlName="selectedCategory"
                                    (onClick)="secondRadioGroup(category)"
                                    />
                                <label [for]="category.key" class="ml-2 text-sm">
                                    {{ category.primaryNumber }} {{ category.streetName }} {{ category.streetSuffix }}, <span *ngIf="category.streetPostdirection">{{category.streetPostdirection}}, </span>
                                    {{ category.cityName }}, {{ category.state }} {{ category.zipCode }}<span *ngIf="category.plus4Code">-</span>{{ category.plus4Code }}
                                </label>
                            </div>
                            <div *ngIf="showAddressError">
                                <p class="text-sm line-height-2">
                                    <span><i class="fa-solid fa-triangle-exclamation"></i></span>
                                    No alternate address recommendations found. Please check the address you entered.
                                </p>
                            </div>
                            <button class="primary default" (click)="confirmAddress()">Confirm my selection</button>
                        </div>
                    </div>
                </div>

                <!-- <div class="form-items-wrapper">
                    <div class="form-item-wrapper">
                        <label for="phone">Phone
                            <span>
                                <i class="fas fa-info-circle fa-sm ml-1" 
                                pTooltip="Enter the U.S. Agent’s phone number" *ngIf="agentForm?.get('isAgentIndividual')?.value === false"></i>
                                <i class="fas fa-info-circle fa-sm ml-1" 
                                pTooltip="Enter the Service Agent Company phone number" *ngIf="agentForm?.get('isAgentIndividual')?.value === true"></i></span>
                        </label>
                        <p-inputMask id="phone" class="avg-input-width"  mask="+1 (999) 999-9999"  [autoClear]="false" formControlName="phone" type="tel" placeholder="+1 (999) 999-9999"></p-inputMask>
                        <div class="alert danger-alert error-font" *ngIf="agentForm?.get('phone')?.errors && agentForm?.get('phone')?.errors.pattern">
                            Please enter valid Phone number. 
                        </div>
                    </div>
                    <div class="form-item-wrapper">
                        <label for="fax" aria-label="fax">Fax</label>
                        <p-inputMask id="fax" class="avg-input-width"  mask="+1 (999) 999-9999" [autoClear]="false" formControlName="fax" aria-labelledby="fax" type="tel" placeholder="+1 (999) 999-9999"></p-inputMask>
                        <div class="alert danger-alert error-font" *ngIf="agentForm?.get('fax')?.errors && agentForm?.get('fax')?.errors.pattern">
                            Please enter a valid Fax number. 
                        </div>
                    </div>
                </div> -->
                <!-- <div class="form-items-wrapper">
                    <div class="form-item-wrapper">
                        <label for="email">Email<span class="asterik">*</span>
                            <span>
                                <i class="fas fa-info-circle fa-sm ml-1" 
                                pTooltip="Enter the U.S. Agent’s email address (name@domain.com)" *ngIf="agentForm?.get('isAgentIndividual')?.value === false"></i> 
                                <i class="fas fa-info-circle fa-sm ml-1" 
                                pTooltip="Enter the Service Agent Company email address (name@domain.com)" *ngIf="agentForm?.get('isAgentIndividual')?.value === true"></i></span>
                        </label>
                        <input pInputText id="email" class="avg-input-width" type="email" maxLength="45" formControlName="email" required/>
                        <div *ngIf="agentForm?.get('email')?.invalid  && agentForm?.get('email')?.errors['required'] && agentForm?.get('email')?.touched"
                        class="alert danger-alert error-font">Email is required. </div>
                        <div class="alert danger-alert error-font" *ngIf=" agentForm?.get('email') && agentForm?.get('email')?.errors && !agentForm?.get('email')?.errors['required']">
                            Please enter a valid email address.
                        </div>
                    </div>
                </div> -->
                
                <div class="border-top-2" *ngIf="agentForm?.get('isAgentIndividual')?.value==true">
                    <h3 class="mt-4">Service Agent Company Representative (Optional)</h3>
                    <div class="form-items-wrapper">
                        <div class="form-item-wrapper mt-4">
                            <label for="pocfirstName">First Name
                                <span><i class="fas fa-info-circle fa-sm ml-1" 
                                    pTooltip="Enter the representative’s legal First Name"></i></span>
                            </label>
                            <input pInputText id="pocfirstName" type="text" maxLength="50" formControlName="pocfirstName"/>
                                <div class="alert danger-alert error-font" *ngIf=" agentForm?.get('pocfirstName')?.errors && agentForm?.get('pocfirstName')?.errors['pattern']">
                                    Please enter valid first name.
                                </div>
                        </div>
                        <div class="form-item-wrapper mt-4">
                            <label for="pocmiddleName">Middle Name
                                <span><i class="fas fa-info-circle fa-sm ml-1" 
                                    pTooltip="Enter the representative’s legal Middle Name/Initial"></i></span>
                            </label>
                            <input pInputText id="pocmiddleName" type="text" maxLength="15" formControlName="pocmiddleName"/>
                            <div class="alert danger-alert error-font" *ngIf=" agentForm?.get('pocmiddleName')?.errors && agentForm?.get('pocmiddleName')?.errors['pattern']">
                                Please enter valid middle name.
                            </div>
                        </div>
                        <div class="form-item-wrapper mt-4">
                            <label for="poclastName">Last Name
                                <span><i class="fas fa-info-circle fa-sm ml-1" 
                                    pTooltip="Enter the representative’s legal Last Name"></i></span>
                            </label>
                            <input pInputText id="poclastName" type="text" maxLength="50" formControlName="poclastName"/>
                                <div class="alert danger-alert error-font" *ngIf="agentForm?.get('poclastName')?.errors && agentForm?.get('poclastName')?.errors['pattern']">
                                    Please enter valid last name.
                                </div>
                        </div>
                        <div class="form-item-wrapper mt-4">
                            <label for="pocsuffix">Suffix
                                <span><i class="fas fa-info-circle fa-sm ml-1" 
                                    pTooltip="Enter a Suffix if your representative’s legal name contains a suffix at the end of their name e.g. SR, JR, II, III"></i></span>
                            </label>
                            <input pInputText id="pocsuffix" type="text" class="suffix-width" maxLength="4" formControlName="pocsuffix" />
                            <div class="alert danger-alert error-font" *ngIf=" agentForm?.get('pocsuffix')?.errors && agentForm?.get('pocsuffix')?.errors['pattern']">
                                Please enter valid suffix.
                            </div>
                        </div>
                    </div>
                    <div class="form-items-wrapper">
                        <div class="form-item-wrapper">
                            <label for="pocPhone">Phone
                                <span><i class="fas fa-info-circle fa-sm ml-1" 
                                    pTooltip="Enter the representative’s phone number"></i></span>
                            </label>
                            <p-inputMask id="pocPhone" class="avg-input-width"  mask="+1 (999) 999-9999"  [autoClear]="false" formControlName="pocPhone" type="tel" placeholder="+1 (999) 999-9999"></p-inputMask>
                            <div class="alert danger-alert error-font" *ngIf=" agentForm?.get('pocPhone')?.errors && agentForm?.get('pocPhone')?.errors.pattern">
                                Please enter valid Phone number. 
                            </div>
                        </div>
                        <div class="form-item-wrapper">
                            <label for="pocFax">Fax</label>
                            <p-inputMask id="pocFax" class="avg-input-width"  mask="+1 (999) 999-9999"  [autoClear]="false" formControlName="pocFax" type="tel" placeholder="+1 (999) 999-9999"></p-inputMask>
                            <div class="alert danger-alert error-font" *ngIf=" agentForm?.get('pocFax')?.errors && agentForm?.get('pocFax')?.errors.pattern">
                                Please enter a valid Fax number. 
                            </div>
                        </div>
                    </div>
                    <div class="form-items-wrapper">
                        <div class="form-item-wrapper">
                            <label for="pocemail">Email
                                <span><i class="fas fa-info-circle fa-sm ml-1" 
                                    pTooltip="Enter the representative’s email address (name@domain.com)"></i></span>
                            </label>
                            <input pInputText id="pocemail" class="avg-input-width" type="email" maxLength="45" formControlName="pocEmail"/>
                            <div class="alert danger-alert error-font" *ngIf=" agentForm?.get('pocEmail') && agentForm?.get('pocEmail')?.errors">
                                Please enter a valid email address.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-items-wrapper">
                    <p-checkbox 
                        formControlName="certify" 
                        value="Certify" 
                        inputId="certify" 
                        class="mr-1"/>
                    <label for="certify">I certify that all statements and answers provided by me on this form are complete and true to the best of my knowledge and I agree that FAA may serve documents addressed to me on my designated U.S. agent. I understand that my U.S. agent is responsible for timely transmitting these documents to me and that my U.S. agent is aware of this responsibility and has agreed to it. I have also read and understand the Privacy Act statement that accompanies this form.</label>
                </div>
                <div>
                    <button type="submit"  class="primary default" (click)="handleaddAgent()">Submit</button>
                    <button class="secondary default" (click)="closeAgentModal()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
