<div>
  <div class="devkit-dot-microbar-wrapper">
    <div class="devkit-dot-microbar">
      <div class="dot-logo-wrapper">
          <a href="https://www.transportation.gov"
          onkeypress="goToLink(&quot;https://www.transportation.gov/&quot;)"><img class="logo-dot"
            src="../../../assets/logo-dot-new.png" alt="DOT logo" aria-describedby="dot-logo-title" target="_blank">
          <span class="dot-logo-title" id="dot-logo-title">United States Department of Transportation</span></a>
      </div>
    </div>
  </div>
  <div class="devkit-skip-to-main" id="devkit-skip-to-main">
    <a href="#devkit-skip-to-main">Skip to Main Content</a>
  </div>
  <header class="ext-header">
    <div class="devkit-mobile-menu" tabindex="0">
      <div class="devkit-icon-wrapper">
        <span class="fa fa-bars" aria-hidden="true" title="Menu"></span><span class="devkit-menu-text"></span>
      </div>
    </div>
    <div class="devkit-menu-overlay">
      <div class="devkit-greeting-wrapper" id="devkit-greeting-wrapper" *ngIf="userName">
        <p>
          <span class="devkit-greeting">Welcome, </span>
          <span class="devkit-greeting-user" *ngIf="!userName">Guest</span>
          <span class="devkit-greeting-user"*ngIf="userName">{{userName}}</span>
        </p>
      </div>
      <div class="devkit-close-menu">
        <div class="fa fa-times" aria-hidden="true" title="Close" aria-label="Close" tabindex="0"></div>
      </div>
    </div>
    <div class="devkit-ext-site-logo">
      <img class="devkit-logo-faa" src="assets/images/FAA-logo.svg" alt="FAA logo" aria-describedby="devkit-logo-title"
        width="84" height="84">
      <div class="devkit-logo-title" id="devkit-logo-title">FAA</div>
    </div>
    <h1 class="devkit-ext-app-title">
      U.S. Agent for Service (USAS)
    </h1>
    <div class="devkit-greeting-wrapper" id="devkit-greeting-wrapper" *ngIf="userName">
      <p>
        <span class="devkit-greeting">Welcome, </span>
        <span class="devkit-greeting-user" *ngIf="!userName">Guest</span>
        <span class="devkit-greeting-user" *ngIf="userName">{{userName}}</span>
      </p>
    </div>
    <div class="devkit-search-wrapper" id="devkit-search-wrapper" *ngIf="headerLandingFlag">
      <input class="devkit-header-search-input" type="text" placeholder="Search" aria-label="Search" role="search">
      <button class="devkit-btn-search" type="submit" aria-label="Search">
        Search
      </button>
      <button class="devkit-search-icon" title="Search">
        <span class="fa fa-search" title="Search icon" aria-label="Search Icon"></span>
      </button>
      <button class="devkit-close-search" aria-label="close" title="Close">
        <span class="fa fa-times" title="Close" aria-label="Close"></span>
      </button>
    </div>
    <div class="devkit-ext-toolbar-wrapper" id="devkit-toolbar-wrapper">
      <div class="devkit-toolbar-icon" id="devkit-signin-wrapper" tabindex="0" *ngIf="userData">
        <a href="#" tabindex="-1" (click)="signOut($event)"><span class="far fa-user" aria-hidden="true" title="Sign In"
            aria-label="Sign In"></span><span class="devkit-tool-bar-text" aria-label="Sign In">Sign out</span></a>
      </div>
      <!-- <div class="devkit-toolbar-icon" id="devkit-search-icon-wrapper">
        <span class="fa fa-search" title="Search" aria-label="Search" aria-haspopup="true"
          aria-controls="devkit-search-wrapper" tabindex="0"></span><span
          class="devkit-tool-bar-text devkit-search-text" aria-label="Search">Search</span>
      </div> -->
      <div class="devkit-toolbar-icon" id="devkit-help-icon-wrapper" aria-haspopup="true"
        aria-controls="devkit-help-content-wrapper" tabindex="0">
        <span class="far fa-question-circle" aria-hidden="true" title="Contact" aria-label="Contact" role="img"></span><span
          class="devkit-tool-bar-text" aria-label="Contact"><a class="devkit-left-nav-link no-underline link-button cursor-pointer ml-0" (click)="contactUs()" aria-label="Link to contact us"
          title="Contact Us">Contact</a></span>
        <!-- <div class="devkit-ext-help-content-wrapper" id="devkit-help-content-wrapper" style="display: none;">
          <div class="devkit-help-item-wrapper">
             <span class="item-title">Questions?</span><span class="item-content"><a href="mailto:AITArchitectureDevKit@faa.gov">AITArchitectureDevKit@faa.gov</a></span>
          </div>
        </div> -->
      </div>
    </div>
  </header>
  <nav class="devkit-ext-nav-wrapper" role="navigation" aria-label="Main Navigation" *ngIf="userData?.userIsActive === 'Y'">
    <ul class="devkit-nav-links" role="menubar">
      <li class="devkit-nav-link" role="menubar" style="margin-left: 10px;" *ngIf="isExternalUser">
        <a class="devkit-main-menu-link" role="menuitem" aria-haspopup="true" aria-expanded="false" tabindex="0" routerLink="/landing">Manage
          U.S. Agent</a>
      </li>
      <li class="devkit-nav-link" role="menubar" style="margin-left: 10px;" *ngIf="isInternalUser || isInternalAdmin">
        <a class="devkit-main-menu-link" role="menuitem" aria-haspopup="true" aria-expanded="false" tabindex="0" routerLink="/search">Search</a>
      </li>
      <li class="devkit-nav-link" role="menubar" style="margin-left: 10px;" *ngIf="false">
        <a class="devkit-main-menu-link" role="menuitem" aria-haspopup="true" aria-expanded="false" tabindex="0">Reports</a>
      </li>
      <li class="devkit-nav-link" role="menubar" style="margin-left: 10px;" *ngIf="isInternalAdmin">
        <a class="devkit-main-menu-link" role="menuitem" aria-haspopup="true" aria-expanded="false" tabindex="0" routerLink="/manage-users">Manage FAA Users</a>
      </li>
      <li class="devkit-nav-link" role="menubar" style="margin-left: 10px;" *ngIf="isExternalUser">
        <a class="devkit-main-menu-link" role="menuitem" aria-haspopup="true" aria-expanded="false" tabindex="0" routerLink="/account-information">Account Profile</a>
      </li>
      <li class="list-none">
        <p-menu #menu [model]="items" [popup]="true">
          <ng-template pTemplate="item" let-item>
              <ng-container *ngIf="item.route; else elseBlock">
                  <a [routerLink]="item.route" class="p-menuitem-link">
                      <span [class]="item.icon"></span>
                      <span class="ml-2">{{ item.label }}</span>
                  </a>
              </ng-container>
              <ng-template #elseBlock>
                  <a [href]="item.url" class="p-menuitem-link">
                      <span [class]="item.icon"></span>
                      <span class="ml-2">{{ item.label }}</span>
                  </a>
              </ng-template>
          </ng-template>
        </p-menu>
        <p-button (onClick)="menu.toggle($event)" label="Resource Links" [text]="true" 
          class="devkit-main-menu-link" icon="pi pi-angle-down" iconPos="right" />
      </li>
    </ul>
  </nav>
</div>
