<div class="grid mb-2 px-6 mt-4">
    <!-- <button type="button" class="secondary default">Back To Sign In</button> -->
    <a class="text-end" style="float:left" href="javascript:history.back()">Go Back</a>
</div>
<div class="grid">
    <div class="lg:col-11 md:col-11 sm:col-12 pl-6">
        <div class="border-left-3 border-yellow-600 bg-panel-gray p-4">
            <div class="text-sm">USER GUIDE</div>
            <h1 class="mt-0 capitalize">Internal User Login Functions</h1>
        </div>
    </div>
</div>
<div class="grid">
    <div class="lg:col-10 md:col-10 sm:col-12 pl-6">
        <div class="container px-4">
            <ol>       
                <li class="mb-3">Launch the
                    following URL - <a href="https://usas.faa.gov/signin"
                        target="_blank">https://usas.faa.gov/signin</a></li>
                <li class="mb-3">You will be
                    redirected to the USAS Login page as in the screenshot below:
                    <img class="w-6 block" src="../../../assets/images/image1.png"></li>
                <li class="mb-3">Click on ‘FAA User
                    Sign in’ Button.</li>
                <li class="mb-3">You will be
                    redirected to the following MyAccess page. Click on ‘Sign in with DOT/FAA PIV’ button
                    <img class="w-6 block" src="../../../assets/images/image27.png"></li>
                
                <li class="mb-3">Click on ‘Sign in
                    with PIV/CAC card’
                    <img class="w-6 block" src="../../../assets/images/image28.png"></li>
                
                <li class="mb-3">Enter your PIN in
                    the ‘ActiveClient Login’ popup window and click ‘OK’
                    <img class="w-6 block mb-2" src="../../../assets/images/image29.png">
                    <img class="w-6 block" src="../../../assets/images/image30.png"></li>
                <li class="mb-3">Once
                    authenticated, you will be re-directed to the Internal landing page for USAS and should be able to see the
                    following:
                    <ul>
                        <li class="mb-3">Search
                            (Internal User Role)</li>
                        <li class="mb-3">Manage
                            Users tabs (Internal Admin Role only)</li>
                        <li class="mb-3">Resource
                            Links</li>
                    </ul>
                
                    <img class="w-6 block" src="../../../assets/images/image31.png">
                </li>
            </ol>
        </div>
    </div>
</div>