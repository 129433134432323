import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService } from '../../_auth/auth.service';

@Component({
  selector: 'app-display-agent-details',
  templateUrl: './display-agent-details.component.html',
  styleUrl: './display-agent-details.component.scss'
})
export class DisplayAgentDetailsComponent {
  visible: boolean = false;
  agentDetails:any;
  userName:string;
  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig, private authService: AuthService){
    this.agentDetails = config?.data;
    const userData = this.authService.getUserData();
    this.userName = userData.firstName + ' ' + userData.lastName;

  }

  showDialog() {
    this.visible = true;
  }
  closeModal(isNewAgent:boolean=false) {
    this.ref.close(isNewAgent);
  }

}
