import { Component } from '@angular/core';

@Component({
  selector: 'app-external',
  templateUrl: './external.component.html',
  styleUrl: './external.component.scss'
})
export class ExternalComponent {

}
