<div *ngIf="routerChanged">
  <app-usas-loading></app-usas-loading>
</div>
<app-user-idle-inactive-timeout></app-user-idle-inactive-timeout>
<faa-header></faa-header>
<!--<faa-nav-bar></faa-nav-bar>-->
<ng-container>
  <div style="min-height: 750px; height: 100%; width: 80%;" class="m-auto">
    <main class="devkit-main-wrapper devkit-no-border m-auto grid-container" id="main-content">
      <div class="devkit-main-content-wrapper"> 
        <div class="flex-container shadow-light-sm m-top-md">
          <div class="flex-50 bg-dark-blue align-center">
            <img src="../assets/plane-img.png" alt="plane-image" width="auto">
          </div>
          <div class="flex-auto bg-light padding-md top-border-dark">
            <div class="devkit-main-head">
              <h1>Coming Soon</h1>
            </div>
            <p style="font-size: 1.25rem;">
              <b>USAS Website is not available. The website should be available by the end of January.</b>
            </p>
            <ul>
              <li class="mt-2">On October 8, 2024, FAA published <span><a target="_blank" href="https://www.federalregister.gov/documents/2024/10/08/2024-22000/us-agents-for-service-on-individuals-with-foreign-addresses-who-hold-or-apply-for-certain" aria-label="Federal Register" title="Federal Register">U.S Agents for Service final rule</a></span></li>
              <li class="mt-2">
                Individuals who have a foreign address and <b>no</b> U.S. physical address of record on file with the FAA are required to designate a U.S. agent for service if they apply for a certificate issued under 14 CFR part 47, 61, 63, 65, 67, or 107 or hold a certificate issued under any of these parts. This requirement applies only to individuals (not entities).
              </li>
            </ul>
            <p>
              <b>Note:</b> If you do not currently have a U.S. physical address of record and are able to provide one, you may do so through the standard processes for <span><a target="_blank" href="https://www.faa.gov/licenses_certificates/airmen_certification/update_address" aria-label="Airman Certification" title="Airman Certification">Airmen</a></span> or <span><a target="_blank" href="https://www.faa.gov/licenses_certificates/aircraft_certification/aircraft_registry/change_of_address" aria-label="Aircraft Registration" title="Aircraft Registration">Aircraft Owners</a></span> in lieu of designating a U.S. Agent for Service.
            </p>
          </div>
        </div>        
      </div>
    </main>
  </div>
</ng-container>
<div class="p-col-12">
  <faa-footer [footerLandingFlag]="landingPageFlag"></faa-footer>
</div>